<template>
  <!-- 折扣券 -->
  <div class="RechargeCoupon">
    <!-- 优惠券总明细表组件 -->
    <DiscountSchemeDetail :schemeInfo="schemeInfo"></DiscountSchemeDetail>
  </div>
</template>

<script>
import DiscountSchemeDetail from "../components/discountSchemeDetail.vue"; //优惠券方案明细表
export default {
  name: "RechargeCoupon",
  components: { DiscountSchemeDetail },
  // 渲染该组件前调用这个路由钩子
  beforeRouteEnter(to, from, next) {
    //因为当守卫执行前,组件实例还没创建
    //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
    //通过'vm'才能访问组件this实例
    next((vm) => {
      if (to.query.type === "add") {
        //新增
        vm.schemeInfo = {
          type: "add", //区分新增/修改类型
          SchemeId: "3", //方案类型id
          title: "折扣券",
        };
      } else {
        //修改
        vm.schemeInfo = {
          type: "update", //区分新增/修改类型
          couponCaseId: to.query.couponCaseId, //优惠券id
        };
      }
    });
  },
  data() {
    return {
      //折扣券方案信息
      schemeInfo: {
        type: undefined, //区分新增/修改类型
        SchemeId: undefined, //方案类型id
        couponCaseId: undefined, //优惠券id
      },
    };
  },
};
</script>

<style></style>
